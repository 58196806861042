.custom-radio-button {
  .custom-radio-button_styled {
    height: 25px;
    width: 25px;
  }

  input:checked + .custom-radio-button_styled::after {
    @apply absolute block rounded-full opacity-100 bg-black;
    content: '';
    left: 3px;
    top: 3px;
    height: 13px;
    width: 13px;
  }
}
